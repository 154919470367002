<!-- Button -->
<style>
    .mdc-radio{
        margin: 0px !important;
        padding: 5px !important;
    }
</style>
<button mat-icon-button [matMenuTriggerFor]="clientActions">
    <span class="relative">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full mr-px mb-px bg-green-500"></span>
    </span>
</button>

<mat-menu [xPosition]="'before'" #clientActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            @if (lang == "fr") {
            <span>Connecté en tant que</span>
            }@else if (lang == "en") {
            <span>Connected as</span>
            }
            <span class="mt-1.5 text-md font-medium"> {{ client.first_name }} {{ client.last_name }}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        @if (lang == "fr") {
        <span>Profile</span>
        }@else if (lang == "en") {
        <span>Profil</span>
        }
    </button>
    <!-- <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>Settings</span>
    </button> -->
    <!-- <button
        mat-menu-item
        [matMenuTriggerFor]="clientStatus">
        <mat-icon [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'"></mat-icon>
        <span>Status</span>
    </button> -->
    <!-- <mat-divider class="my-2"></mat-divider> -->
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        @if (lang == "fr") {
        <span>Se déconnecter</span>
        }@else if (lang == "en") {
        <span>Sign out</span>
        }
    </button>
</mat-menu>

<mat-menu class="client-status-menu" #clientStatus="matMenu">
    <button mat-menu-item (click)="updateClientStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button mat-menu-item (click)="updateClientStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button mat-menu-item (click)="updateClientStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button mat-menu-item (click)="updateClientStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>