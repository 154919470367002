import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Reservation } from 'app/core/reservation/reservation.types';
import { ReservationService } from 'app/core/reservation/reservation.service';
import { MatIconModule } from '@angular/material/icon';
import { Box } from 'app/core/box/box.types';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Router } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { AuthClientService } from 'app/core/auth/auth-client.service';

@Component({
  selector: 'reservations-details',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTableModule, MatButtonModule, MatButtonToggleModule],
  templateUrl: './reservations-details.component.html',
  styleUrl: './reservations-details.component.scss'
})

export class ReservationsDetailsComponent implements OnInit {
  reservation?: Reservation;
  numberOfDays: number = 0;
  productsTotal: number = 0;
  reservationTotal: number = 0;
  activePanel: string = "General"
  boxes?: Box[];
  lang: string = 'fr';
  blob: any = new Blob;
  displayedColumnsbBoxes: string[] = ['number', 'width', 'height', 'off_center', 'actions'];
  userIsAdmin: boolean = false;

  constructor(
    private _reservationService: ReservationService,
    private _router: Router,
    private _fuseConfirmationService: FuseConfirmationService,
    private _clientService: AuthClientService
  ) { }

  ngOnInit(): void {
    if (this._clientService.typeUser == 'Client') {
      if (this._router.url.includes('/fr')) {
        this.lang = 'fr';
      } else if (this._router.url.includes('/en')) {
        this.lang = 'en';
      }
    }
    
    this.reservation = this._reservationService.selectedReservation$.value;
    this.userIsAdmin = this._clientService.typeUser != 'Client'

    this.numberOfDays = Math.floor((new Date(this._reservationService.selectedReservation$.value.to_date).getTime() - new Date(this._reservationService.selectedReservation$.value.from_date).getTime()) / (1000 * 60 * 60 * 24));
    this.productsTotal = Number(this.reservation.products.reduce((acc, product) => acc + (product.price * product.quantity), 0));
    this.reservationTotal = this.productsTotal + this.reservation.total_tax_incl;
    this.getReservationBoxes();
  }

  goToEditReservation(reservation: Reservation) {
    this._router.navigate(['/admin/reservations/new/', reservation.id]);
  }

  getReceipt(reservation: Reservation) {
    const confirmation = this._fuseConfirmationService.open({
      title: 'Recu reservation',
      icon: {
        color: 'primary'
      },
      message: 'Voulez-vous vraiment générer le reçu de cette reservation ?',
      actions: {
        confirm: {
          label: 'FR',
          color: 'accent'
        },
        cancel: {
          label: 'EN'
        }
      },
    });


    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        this._reservationService.getReceiptReservation(reservation, 'fr').subscribe((data: any) => {
          this.blob = new Blob([data], { type: 'application/pdf' });
          var downloadURL = window.URL.createObjectURL(data);
          window.open(downloadURL, '_blank', "recuPaiement.pdf")
        });
      } else {
        //cancelled
        this._reservationService.getReceiptReservation(reservation, 'en').subscribe((data: any) => {
          this.blob = new Blob([data], { type: 'application/pdf' });
          var downloadURL = window.URL.createObjectURL(data);
          window.open(downloadURL, '_blank', "recuPaiement.pdf")
        });
      }
    });
  }

  getSimpleFacture(reservation: Reservation) {
    const confirmation = this._fuseConfirmationService.open({
      title: 'Facture reservation',
      icon: {
        color: 'primary'
      },
      message: 'Voulez-vous vraiment générer la facture de cette reservation ?',
      actions: {
        confirm: {
          label: 'FR',
          color: 'accent'
        },
        cancel: {
          label: 'EN'
        }
      },
    });


    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        this._reservationService.getFactureReservation(reservation, 'fr', 'client/').subscribe((data: any) => {
          this.blob = new Blob([data], { type: 'application/pdf' });
          var downloadURL = window.URL.createObjectURL(data);
          window.open(downloadURL, '_blank', "facture.pdf")
        });
      } else {
        this._reservationService.getFactureReservation(reservation, 'en', 'client/').subscribe((data: any) => {
          this.blob = new Blob([data], { type: 'application/pdf' });
          var downloadURL = window.URL.createObjectURL(data);
          window.open(downloadURL, '_blank', "facture.pdf")
        });

      }
    });
  }

  getFacture(reservation: Reservation) {
    if (this._clientService.typeUser == 'Client') {
      this._reservationService.getFactureReservation(reservation, this.lang, 'client/').subscribe((data: any) => {
        this.blob = new Blob([data], { type: 'application/pdf' });
        var downloadURL = window.URL.createObjectURL(data);
        window.open(downloadURL, '_blank', "facture.pdf")
      });
    } else {
      const confirmation = this._fuseConfirmationService.open({
        title: 'Facture reservation',
        icon: {
          color: 'primary'
        },
        message: 'Voulez-vous vraiment générer la facture de cette reservation ?',
        actions: {
          confirm: {
            label: 'FR',
            color: 'accent'
          },
          cancel: {
            label: 'EN'
          }
        },
      });


      confirmation.afterClosed().subscribe((result) => {
        if (result === 'confirmed') {
          this._reservationService.getFactureReservation(reservation, 'fr').subscribe((data: any) => {
            this.blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = window.URL.createObjectURL(data);
            window.open(downloadURL, '_blank', "facture.pdf")
          });
        } else {
          this._reservationService.getFactureReservation(reservation, 'en').subscribe((data: any) => {
            this.blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = window.URL.createObjectURL(data);
            window.open(downloadURL, '_blank', "facture.pdf")
          });

        }
      });
    }
  }

  getReservationBoxes() {
    this._reservationService.getBoxes(this.reservation).subscribe((boxes: any) => {
      this.boxes = boxes.data;
    })
  }
}
