import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AuthClientGuard } from './core/auth/guards/auth-client.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'reservations/fr' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'admin/reservations/list' },
    { path: 'signed-in-redirect-client', pathMatch: 'full', redirectTo: 'fr/client/reservations/list' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'admin/confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'admin/forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: 'admin/reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'admin/sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
            { path: 'admin/sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') },
            { path: 'fr/client/sign-in', loadChildren: () => import('app/modules/auth/client/client.routes') },
            { path: 'en/client/sign-in', loadChildren: () => import('app/modules/auth/client/client.routes') },
        ]
    },

    // Auth routes for authenticated users
    {
        path: 'admin',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') }
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes') },
        ]
    },

    // Admin routes
    {
        path: 'admin',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: '', redirectTo: 'reservations/list', pathMatch: 'full' },
            { path: 'example', loadChildren: () => import('app/modules/admin/example/example.routes') },
            { path: 'boxes', loadChildren: () => import('app/modules/admin/boxes/boxes.routes') },
            { path: 'client', loadChildren: () => import('app/modules/admin/clients/clients.routes') },
            { path: 'reservations', loadChildren: () => import('app/modules/admin/reservations/reservations.routes') },
            { path: 'products', loadChildren: () => import('app/modules/admin/products/products.routes') },
            { path: 'states', loadChildren: () => import('app/modules/admin/states/states.routes') },
            { path: 'dashboard', loadChildren: () => import('app/modules/admin/dashboard/dashboard.routes') },
            { path: 'users', loadChildren: () => import('app/modules/admin/users/users.routes') },
            { path: 'time-slots', loadChildren: () => import('app/modules/admin/time-slots/time-slots.routes') },
        ]
    },
    {
        path: 'fr/client',
        canActivate: [AuthClientGuard],
        canActivateChild: [AuthClientGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        data: {
            layout: 'thin'
        },
        children: [
            { path: 'reservations', loadChildren: () => import('app/modules/client/reservations/reservations.routes') }
        ]
    },
    {
        path: 'en/client',
        canActivate: [AuthClientGuard],
        canActivateChild: [AuthClientGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        data: {
            layout: 'thin'
        },
        children: [
            { path: 'reservations', loadChildren: () => import('app/modules/client/reservations/reservations.routes') }
        ]
    },
    {
        path: 'reservations',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'fr', loadChildren: () => import('app/modules/public/reservations/reservations.routes') },
            { path: 'en', loadChildren: () => import('app/modules/public/reservations/reservations.routes') }
        ]
    },

];
