import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { PaymentSignalState, Reservation } from './reservation.types';
import { BehaviorSubject, Observable, ReplaySubject, tap } from 'rxjs';
import { join } from 'lodash';
import { Box } from '../box/box.types';


@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  private _reservations: ReplaySubject<Reservation[]> = new ReplaySubject<Reservation[]>(1);
  private _reservation: BehaviorSubject<Reservation | null> = new BehaviorSubject<Reservation | null>(null);
  total_tax_excl: BehaviorSubject<Number | null> = new BehaviorSubject<Number | null>(null);
  tax_rate: BehaviorSubject<Number | null> = new BehaviorSubject<Number | null>(null);
  total_tax_incl: BehaviorSubject<Number | null> = new BehaviorSubject<Number | null>(null);
  payment_signal: BehaviorSubject<PaymentSignalState> = new BehaviorSubject<PaymentSignalState>(PaymentSignalState.STAND_BY);
  selectedReservation$: BehaviorSubject<Reservation> = new BehaviorSubject<Reservation>(null)

  headers = {
    // reportProgress: true,
    responseType: 'blob' as 'json',
    headers: new HttpHeaders({
      "Accept": "application/json",
      "Content-Type": "application/json"
    })
  }

  constructor(private _httpClient: HttpClient) { }

  set reservations(value: Reservation[]) {
    this._reservations.next(value);
  }

  get reservations$(): Observable<Reservation[]> {
    return this._reservations.asObservable();
  }

  set reservation(value: Reservation) {
    this._reservation.next(value);
  }

  get reservation$(): BehaviorSubject<Reservation> {
    // return this._reservation.getValue();
    return this._reservation;
  }

  set totalTaxExcl(value: Number) {
    this.total_tax_excl.next(value);
  }

  get totalTaxExcl$(): BehaviorSubject<Number> {
    return this.total_tax_excl;
  }

  set taxeRate(value: Number) {
    this.tax_rate.next(value);
  }

  get taxeRate$(): BehaviorSubject<Number> {
    return this.tax_rate;
  }

  set totalTaxIncl(value: Number) {
    this.total_tax_incl.next(value);
  }

  get totalTaxIncl$(): BehaviorSubject<Number> {
    return this.total_tax_incl;
  }

  set paymentSignal(value: PaymentSignalState) {
    this.payment_signal.next(value);
  }

  get paymentSignal$(): BehaviorSubject<PaymentSignalState> {
    return this.payment_signal;
  }

  getAll(): Observable<Reservation[]> {
    return this._httpClient.get<Reservation[]>(environment.api + '/reservations').pipe(
      tap((reservation) => {
        this._reservations.next(reservation);
      })
    );
  }

  getReservationsClient(client_id: number): Observable<Reservation[]> {
    return this._httpClient.get<Reservation[]>(environment.api + '/reservations/client/' + client_id).pipe(
      tap((reservation) => {
        this._reservations.next(reservation);
      })
    );
  }

  getReservations(searchObj?: any, sortObj?: any): Observable<Reservation[]> {
    return this._httpClient.post(environment.api + '/reservations/advancedSearch', { searchObj: searchObj, sortObj: sortObj }).pipe(
      tap((dataReservation: Reservation[]) => {
        this._reservations.next(dataReservation);
      })
    );
  }

  paymentReservation(request: any): Observable<any> {
    return this._httpClient.post<any>(environment.api + '/reservations/payment', request);
  }

  getMyReservations(): Observable<Reservation[]> {
    return this._httpClient.get<Reservation[]>(environment.api + '/reservation/client/me').pipe(
      tap((reservation) => {
        this._reservations.next(reservation);
      })
    );
  }

  getReservationWithUrl(url: any): Observable<Reservation[]> {
    return this._httpClient.get<Reservation[]>(url).pipe(
      tap((reservation) => {
        this._reservations.next(reservation);
      })
    );
  }

  addReservation(reservation: Reservation) {
    return this._httpClient.post(`${environment.api}/reservations`, reservation);
  }

  addPublicReservation(reservation: Reservation) {
    return this._httpClient.post(`${environment.api}/public/reservations`, reservation);
  }

  getDetaiReservation(id: number): Observable<Reservation> {
    return this._httpClient.get<Reservation>(`${environment.api}/reservations/${id}`);
  }

  getBoxes(reservation: Reservation): Observable<Box> {
    return this._httpClient.get<Box>(`${environment.api}/reservations/boxes/${reservation.id}`);
  }
  editReservation(reservation: Reservation) {
    return this._httpClient.put(`${environment.api}/reservations/${reservation.id}`, reservation);
  }

  getReservation(id: string): Observable<Reservation> {
    return this._httpClient.get<Reservation>(`${environment.api}/reservations/${id}`);
  }

  cancelReservation(reservation: Reservation) {
    return this._httpClient.get(`${environment.api}/reservations/${reservation.id}/cancel`);
  }

  validateReservation(reservation: Reservation) {
    return this._httpClient.get(`${environment.api}/reservations/${reservation.id}/validate`);
  }

  sendReservation(reservation: Reservation, lang: string) {
    return this._httpClient.get(`${environment.api}/reservations/sendMail/${reservation.id}/confirm/${lang}`);
  }

  refund(reservation: Reservation) {
    return this._httpClient.get(`${environment.api}/reservations/${reservation.id}/refund`);
  }

  refundWithoutPayment(reservation: Reservation) {
    return this._httpClient.get(`${environment.api}/reservations/${reservation.id}/refund-without-payment`);
  }

  getAdvancedSearchReservationClient(searchObj?: any, sortObj?: any, client_id?: number): Observable<Reservation[]> {
    return this._httpClient.post<Reservation[]>(environment.api + '/reservations/advancedSearch/client/' + client_id, { searchObj: searchObj, sortObj: sortObj }).pipe(
      tap((reservation) => {
        this._reservations.next(reservation);
      })
    );
  }

  getReceiptReservation(reservation: Reservation,lang:string): Observable<any> {
    return this._httpClient.get<any>(`${environment.api}/reservations/${reservation.id}/receipt/${lang}`, this.headers);
  }

  getFactureReservation(reservation: Reservation, lang: string,client=''): Observable<any> {
    return this._httpClient.get<any>(`${environment.api}/reservations/${reservation.id}/facture/${client}${lang}`, this.headers);
  }

  exportReservationPdf(data: any): Observable<any> {
    let pdfHeaders = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'Accept':'application/pdf',
        'Authorization':'Bearer '
      }),
      responseType: 'blob' as 'json'
    }
    return this._httpClient.post<any>(`${environment.api}/reservations/export/pdf`, data, pdfHeaders);
  }

  exportReservationExcel(data: any): Observable<any> {
    let excelHeaders = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'Accept':'application/vnd.ms-excel',
        'Authorization':'Bearer '
      }),
      responseType: 'blob' as 'json'
    }
    return this._httpClient.post<any>(`${environment.api}/reservations/export/excel`, data, excelHeaders);
  }
}
