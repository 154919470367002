import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { AuthClientService } from '../auth-client.service';

export const AuthClientGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    let lang:string = 'fr';
    if (router.url.includes('/fr')) {
        lang = 'fr';
    } else if (router.url.includes('/en')) {
        lang = 'en';
    }
    // Check the authentication status
    return inject(AuthClientService).check().pipe(
        switchMap((authenticated) => {
            // If the client is not authenticated...
            if (!authenticated) {
                // Redirect to the sign-in page with a redirectUrl param
                const redirectURL = state.url === `/`+lang+'/client/sign-out' ? '' : `redirectURL=${state.url}`;
                const urlTree = router.parseUrl(`/`+lang+`/client/sign-in?${redirectURL}`);

                return of(urlTree);
            }

            // Allow the access
            return of(true);
        }),
    );
};
