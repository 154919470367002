import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, map, Observable, of, ReplaySubject, switchMap, tap, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { Client } from './client.types';
import { UserService } from '../user/user.service';
import { AuthUtils } from '../auth/auth.utils';
// import { Client } from 'app\core\client\client.types.ts';

@Injectable({
    providedIn: 'root'
})
export class ClientService
{
    private _authenticated: boolean = false;
    private _clients: ReplaySubject<Client[]> = new ReplaySubject<Client[]>(1);
    // private _client: ReplaySubject<Client> = new ReplaySubject<Client>(1);
    private _client: BehaviorSubject<Client | null> = new BehaviorSubject<Client | null>(null);
    private _closeClient: BehaviorSubject<Boolean | null> = new BehaviorSubject<Boolean | null>(false);

    // private _clientService = inject(ClientService);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for client
     *
     * @param value
     */

    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    set typeUser(typeUser: string)
    {
        localStorage.setItem('typeUser', typeUser);
    }

    get typeUser(): string
    {
        return localStorage.getItem('typeUser') ?? '';
    }

    set clients(value: Client[])
    {
        // Store the value
        this._clients.next(value);
    }

    get clients$(): Observable<Client[]>
    {
        return this._clients.asObservable();
    }

    set client(value: Client)
    {
        // Store the value
        this._client.next(value);
    }

    get client$(): BehaviorSubject<Client>
    {
        return this._client;
    }

    set closeClient(value: Boolean)
    {
        // Store the value
        this._closeClient.next(value);
    }

    get closeClient$(): BehaviorSubject<Boolean>
    {
        return this._closeClient;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(environment.api+'/auth/client/login', credentials).pipe(
            switchMap((response: any) =>
            {
                if(response.status){
                    // Store the access token in the local storage
                    this.accessToken = response.access_token;
                    this.typeUser = response.type_user;                    

                    // Set the authenticated flag to true
                    this._authenticated = true;
                    
                    // Store the user on a separate variable
                    const clientData :Client = response.client;
                    
                    // Assign the client to the _clients object
                    this.client = clientData;
                }
                // Return a new observable with the response
                return of(response);
            }),
        );
    }

    checkmailClient(email: string): Observable<any>
    {
        return this._httpClient.post(environment.api+'/client/checkmail', {email: email});
    }

    getAll(): Observable<Client[]>
    {
        return this._httpClient.get<Client[]>(environment.api+'/clients');
    }

    filterClients(event): Observable<Client[]>
    {
        return this._httpClient.post<Client[]>(environment.api+'/clients/filterClient',{search: event});
    }

    addClient(client: Client): Observable<Client>
    {
        if (client.id != null && client.id != 0) {            
            return this._httpClient.put<Client>(environment.api+'/clients/update/'+client.id,client);
        }
           
        return this._httpClient.post<Client>(environment.api+'/clients/add',client);
    }

    signInUsingToken(): Observable<any>
    {
        // Sign in using the token        
        return this._httpClient.post(environment.api+'/auth/client/signInWithToken', {
            accessToken: this.accessToken,
        }).pipe(
            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                // Replace the access token with the new one if it's available on
                // the response object.
                if ( response.accessToken )
                {
                    this.accessToken = response.accessToken;
                    this.typeUser = response.type_user;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                 // Store the user on a separate variable
                 const clientData :Client = response.client;
                    
                 // Assign the client to the _clients object
                 this.client = clientData;
                 if (response.type_user != 'Client') {
                    this._authenticated = true;
                    return of(false);
                 }
                // Return true
                return of(true);
            }),
        );
    }

    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    update(client: Client): void
    {
        //not yet
        this._httpClient.put(environment.api+'/clients/update/'+client.id, client).subscribe({
            next: (client: Client) => {
                this._client.next(client);
            },
            error: error => {
                console.error('There was an error!', error);
            }
        });
            
    }

    exportClientList(): Observable<any>
    {
        return this._httpClient.get(environment.api+'/client/export', {responseType: 'blob'});
    }

    delete(id: number): Observable<any>
    {
        return this._httpClient.delete(environment.api+'/clients/delete/'+id);
    }
}
