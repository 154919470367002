import { Component, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { BooleanInput } from '@angular/cdk/coercion';
import { ClientService } from 'app/core/client/client.service';
import { Client } from 'app/core/client/client.types';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'client',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'client',
  imports: [CommonModule, MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule, MatRadioModule],
  templateUrl: './client.component.html',
})
export class ClientComponent implements OnInit, OnDestroy {

  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = true;
  client: Client;
  lang: string = 'fr';

  private _unsubscribeAll: Subject<any> = new Subject<any>();


  /**
     * Constructor
     */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _clientService: ClientService,
    private dateAdapter: DateAdapter<Date>,
    private _activatedRoute: ActivatedRoute
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
    {
        if(this._router.url.includes('/fr')){
            this.lang = 'fr';
        }else if(this._router.url.includes('/en')){
            this.lang = 'en';
        }
        
        // Subscribe to client changes
        this._clientService.client$.pipe(takeUntil(this._unsubscribeAll)).subscribe((client: Client) =>
            {              
                this.client = client;
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    choseLanguage(lang: string) {
        // this.client = this._clientService.client$.value;
        // this.client.lang = lang;
        //     if(this.client && this.client.lang == 'fr'){
        //         this.dateAdapter.setLocale('fr');
        //     }else if(this.client && this.client.lang == 'en'){
        //         this.dateAdapter.setLocale('en');
        //     }
        // this._clientService.update(this.client);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the client status
     *
     * @param status
     */
    updateClientStatus(status: string): void
    {
        // Return if client is not available
        if ( !this.client )
        {
            return;
        }

        // Update the client
        // this._clientService.update({
        //     ...this.client,
        //     status,
        // }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void
    {
        this._router.navigate(['/admin/sign-out']);
    }
}
