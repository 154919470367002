import { Box } from "../box/box.types";
import { Client } from "../client/client.types";
import { ReservationProduct } from "../reservationProduct/reservationProduct.types";
import { TimeSlot } from "../time-slots/timeSlots.types";
import { User } from "../user/user.types";

export interface Reservation {
    created_at: any;
    user?: User;
    horse_count?: number;
    stallion_count?: number;
    details: any;
    boxes: Box[];
    time_slot: TimeSlot;
    state: any;
    client: Client;
    id: number;
    user_id?: number;
    client_id: number;
    from_date: Date;
    to_date: Date;
    total_tax_excl: number;
    total_tax_incl: number;
    tax_rate: number;
    time_slot_id: number;
    current_state_id: number;
    payment_id: string;
    reference_tva: string;
    total_incl: number;
    total_excl: number;
    should_block_boxes: boolean;
    mut: any;
    products: ReservationProduct[];
}

export enum PaymentSignalState {
    STAND_BY = 0,
    PROCESS_PAYMENT = 1,
    PAYMENT_SUCCESS = 2,
    PAYMENT_FAILED = 3
}