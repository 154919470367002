<div class="h-full overflow-scroll relative flex flex-col">
    <div class="w-full my-3">
        <div class="flex bg-primary-50 justify-evenly mb-2">
            <div class="p-2 flex flex-col justify-center items-center">
                <span class="font-semibold tracking-wider">{{ reservation.from_date | date : 'd/M/yyyy' }}</span>
                <span class="text-gray-500 font-semibold text-md tracking-widest">{{ reservation.time_slot.entry_hour
                    }}</span>
            </div>
            <div class="p-2 px-3.5 flex flex-col justify-center items-center bg-gray-100">
                <div class="text-xl text-gray-500 font-bold">{{ numberOfDays }}</div>
            </div>
            <div class="p-2 flex flex-col justify-center items-center">
                <span class="font-semibold tracking-wider">{{ reservation.to_date | date : 'd/M/yyyy' }}</span>
                <span class="text-gray-500 font-semibold text-md tracking-widest">{{ reservation.time_slot.exit_hour
                    }}</span>
            </div>
        </div>
    </div>
    <div class="w-full bg-white p-3">
        <div class="flex justify-between pb-3">
            <div class="font-bold flex items-center text-xl">
                {{ reservation.client.first_name }} {{ reservation.client.last_name }}
            </div>
            <div>
                <span class="font-semibold text-gray-400 text-sm">#{{ reservation.id }}</span>
            </div>
        </div>
        <div class="w-full border-b mb-3"></div>
        <div class="w-full grid md:grid-cols-2 gap-3">
            <div class="sm:pb-3 sm:border-b md:border-r grid grid-cols-5 gap-4 md:py-1">
                <div class="font-bold text-md col-span-2">
                    @if (lang == "fr") {
                    Raison sociale
                    }@else{
                    Social reason
                    }
                </div>
                <div class="col-span-1">:</div>
                <div class="col-span-2">{{ reservation.client.social_reason ?? '-' }} </div>
                <div class="font-bold text-md col-span-2">
                    @if (lang == "fr") {
                    Référence TVA
                    }@else{
                    VAT reference
                    }
                </div>
                <div class="col-span-1">:</div>
                <div class="col-span-2">{{ reservation.client.reference_tva ?? '-' }} </div>
                <div class="font-bold text-md col-span-2">
                    @if (lang == "fr") {
                    Adresse
                    }@else{
                    Address
                    }
                </div>
                <div class="col-span-1">:</div>
                <div class="col-span-2">{{ reservation.client.address }} </div>
                <div class="font-bold text-md col-span-2">
                    @if (lang == "fr") {
                    Téléphone
                    }@else{
                    Phone
                    }
                </div>
                <div class="col-span-1">:</div>
                <div class="flex items-center gap-2 col-span-2">{{ reservation.client.phone }} </div>
            </div>
            <div class="grid grid-cols-5 gap-4 py-1">
                <div class="font-bold text-md col-span-2">
                    @if (lang == "fr") {
                    Prix boxes TTC
                    }@else{
                    Boxes price
                    }
                </div>
                <div class="col-span-1">:</div>
                <div class="col-span-2">{{ (reservation.total_tax_incl).toFixed(2) }}€ </div>
                <div class="font-bold text-md col-span-2">
                    @if (lang == "fr") {
                    Prix produits TTC
                    }@else{
                    Products price
                    }
                </div>
                <div class="col-span-1">:</div>
                <div class="col-span-2">{{ productsTotal.toFixed(2) }}€ </div>
                <div class="font-bold text-md col-span-2">
                    @if (lang == "fr") {
                    Total TTC
                    }@else{
                    Total tax included
                    }
                </div>
                <div class="col-span-1">:</div>
                <div class="col-span-2">{{ (reservation.total_tax_incl+productsTotal).toFixed(2) }}€ </div>
                <div class="font-bold text-md col-span-2">
                    @if (lang == "fr") {
                    Paiement
                    }@else{
                    Payment
                    }
                </div>
                <div class="col-span-1">:</div>
                <div class="flex items-center gap-2 col-span-2">
                    @if (reservation.current_state_id == 2) {
                    <div class="flex items-center gap-2">
                        <mat-icon class="icon-size-5 text-green-500" svgIcon="heroicons_solid:check-circle"></mat-icon>
                        <span class="text-green-500 font-semibold">
                            @if (lang == "fr") {
                            Payé
                            }@else{
                            Paid
                            }
                        </span>
                    </div>
                    }@else if (reservation.current_state_id == 1) {
                    <div class="flex items-center gap-2">
                        <mat-icon class="icon-size-5 text-red-500" svgIcon="heroicons_outline:x-mark"></mat-icon>
                        <span class="text-red-500 font-semibold">
                            @if (lang == "fr") {
                            En attente
                            }@else{
                            Pending
                            }
                        </span>
                    </div>
                    }@else if (reservation.current_state_id == 3) {
                    <div class="flex items-center gap-2">
                        <mat-icon class="icon-size-5 text-red-500" svgIcon="heroicons_outline:x-mark"></mat-icon>
                        <span class="text-red-500 font-semibold">
                            @if (lang == "fr") {
                            Annulée
                            }@else{
                            Canceled
                            }
                        </span>
                    </div>
                    }@else if (reservation.current_state_id == 4) {
                    <div class="flex items-center gap-2">
                        <mat-icon class="icon-size-5 text-red-500" svgIcon="heroicons_outline:x-mark"></mat-icon>
                        <span class="text-red-500 font-semibold">
                            @if (lang == "fr") {
                            Remboursée
                            }@else{
                            Refunded
                            }
                        </span>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
    <div class="w-full grow grid md:grid-cols-2 py-4">
        <div class="w-full border-r p-3 mt-3 flex flex-col gap-2">
            <div class="font-bold text-xl flex items-center text-gray-500 mb-3">
                Boxes ({{ reservation.details?.length ?? 0 }})
            </div>
            <div class="flex flex-wrap gap-3 w-full">
                @for (detail of reservation.details; track detail.id) {
                <div class="col-span-1 flex items-center px-3 py-2 shadow-md bg-card rounded-lg w-fit">
                    <div class="flex pr-4">
                        <mat-icon class="icon-size-6 text-gray-400" svgIcon="feather:box"></mat-icon>
                    </div>
                    <div class="border-l-2 col-span-2 flex flex-row justify-between px-4 font-[800]">
                        <span class="text-xl text-gray-600"> #{{ detail.box.number }} </span>
                    </div>
                </div>
                }
            </div>
        </div>
        <div class="w-full mt-3 gap-3 px-3">
            <div class="py-1">
                <div class="font-bold text-xl flex items-center text-gray-500 mb-3">
                    @if (lang == "fr") {
                    Produits
                    }@else{
                    Products
                    }
                    ({{ reservation.products?.length ?? 0 }})
                </div>
                <div class="grid gap-3 w-full">
                    @for (reservationProducts of reservation.products; track reservationProducts.id) {
                    <div
                        class="grid grid-cols-12 justify-between px-6 py-5 shadow-md bg-card rounded-lg items-center w-full">
                        <div class="col-span-7 flex items-center">
                            <div class="col-span-2 flex flex-col justify-between px-4">
                                <span class="text-xl text-gray-600"> {{ reservationProducts.product.name }} </span>
                                <span class="text-md text-gray-600"> {{ reservationProducts.product.description }}
                                </span>
                            </div>
                        </div>
                        <div class="col-span-5 flex flex-col text-sm gap-0.5">
                            <div class="grid grid-cols-2 gap-3">
                                @if (lang == "fr") {
                                <div>PU: </div>
                                }@else{
                                <div>Unit price: </div>
                                }
                                <div>{{ reservationProducts.price }} €</div>
                            </div>
                            <div class="grid grid-cols-2 gap-3">
                                @if (lang == "fr") {
                                <div>QTE: </div>
                                }@else{
                                <div>Quantity: </div>
                                }
                                <div>{{ reservationProducts.quantity }}</div>
                            </div>
                            <div class="grid grid-cols-2 gap-3 font-semibold text-md">
                                <div>Total: </div>
                                <div>{{ reservationProducts.price * reservationProducts.quantity }} €</div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
        <div class="w-full p-3 mt-3 flex flex-col justify-end items-end md:col-span-2 pb-15">
            <div class="sm:grid sm:grid-cols-1 md:flex justify-end md:items-center gap-2 w-full ml-auto mb-1">
                <button mat-stroked-button class="w-full bg-white" (click)="getSimpleFacture(reservation)">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:document-check'"></mat-icon>
                    @if (lang == "fr") {
                    <span class="ml-2">Facture</span>
                    }@else{
                    <span class="ml-2">Invoice</span>
                    }
                </button>
                @if(userIsAdmin){
                <button mat-stroked-button class="w-full bg-white" (click)="getFacture(reservation)">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:document-check'"></mat-icon>
                    @if (lang == "fr") {
                    <span class="ml-2">Facture detaillée </span>
                    }@else{
                    <span class="ml-2">Detailed invoice</span>
                    }
                </button>
                }
                <button mat-stroked-button class="w-full bg-white" (click)="getReceipt(reservation)">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:document-arrow-down'"></mat-icon>
                    @if (lang == "fr") {
                    <span class="ml-2">Bon réservation</span>
                    }@else{
                    <span class="ml-2">Receipt</span>
                    }
                </button>
            </div>
            <div class="sm:grid sm:grid-cols-1 md:flex justify-end md:items-center gap-2 w-full ml-auto mb-1">
                <button mat-stroked-button class="w-full bg-white" (click)="goToEditReservation(reservation)">
                    <mat-icon class="icon-size-5 text-blue-600" [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                    @if (lang == "fr") {
                    <span class="ml-2">Modifier</span>
                    }@else{
                    <span class="ml-2">Edit</span>
                    }
                </button>
                <button mat-stroked-button class="w-full bg-white">
                    <mat-icon class="icon-size-5 text-red-600" [svgIcon]="'mat_outline:restart_alt'"></mat-icon>
                    @if (lang == "fr") {
                    <span class="ml-2">Rembourser</span>
                    }@else{
                    <span class="ml-2">Refund</span>
                    }
                </button>
            </div>
        </div>
        <div class="block md:hidden">
            <br>
            <br>
        </div>
    </div>
</div>