/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'new_reservation',
        title: 'Nouvelle réservation',
        type: 'basic',
        icon: 'heroicons_outline:plus-circle',
        tooltip: 'Nouvelle réservation',
        link: '/fr/client/reservations/new'
    },
    {
        id: 'reservation_list',
        title: 'Liste des réservation',
        type: 'basic',
        tooltip: 'Liste des réservation',
        icon: 'heroicons_outline:ticket',
        link: '/fr/client/reservations/list'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Tableau de bord',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/admin/dashboard'
    },
    {
        id: 'reservations',
        title: 'Réservations',
        type: 'aside',
        icon: 'heroicons_outline:ticket',
        children: [
            {
                id: 'new_reservation',
                title: 'Nouvelle réservation',
                type: 'basic',
                icon: 'heroicons_outline:plus-circle',
                link: '/admin/reservations/new'
            },
            {
                id: 'reservation_list',
                title: 'Liste des réservation',
                type: 'basic',
                icon: 'heroicons_outline:document-duplicate',
                link: '/admin/reservations/list'
            },
            {
                id: 'reservation_planning',
                title: 'Planning des réservations',
                type: 'basic',
                icon: 'heroicons_outline:calendar-days',
                link: '/admin/reservations/planning'
            }
        ],
    },
    {
        id: 'boxes',
        title: 'Boxes',
        type: 'aside',
        icon: 'mat_outline:meeting_room',
        children: [
            {
                id: 'new_box',
                title: 'Nouvelle box',
                type: 'basic',
                icon: 'heroicons_outline:plus-circle',
                link: '/admin/boxes/new'
            },
            {
                id: 'box_list',
                title: 'Liste des boxes',
                type: 'basic',
                icon: 'heroicons_outline:document-duplicate',
                link: '/admin/boxes/list'
            },
            {
                id: 'box_planning',
                title: 'Planning des boxes',
                type: 'basic',
                icon: 'heroicons_outline:calendar-days',
                link: '/admin/boxes/planning'
            }
        ],
    },
    {
        id: 'clients',
        title: 'Clients',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/admin/client/list'
    },
    {
        id: 'products',
        title: 'Products',
        type: 'basic',
        icon: 'heroicons_outline:building-storefront',
        link: '/admin/products'
    },
    {
        id: 'settings',
        title: 'Paramétrage',
        type: 'aside',
        icon: 'mat_outline:settings',
        children: [
            {
                id: 'admin_list',
                title: 'Utilisateurs',
                type: 'basic',
                icon: 'mat_outline:room_preferences',
                link: '/admin/users'
            },
            // {
            //     id: 'box_states',
            //     title: 'Etats des boxes',
            //     type: 'basic',
            //     icon: 'mat_outline:room_preferences',
            //     link: '/child2'
            // },
            {
                id: 'time_slots',
                title: 'Plages horaires',
                type: 'basic',
                icon: 'heroicons_outline:clock',
                link: '/admin/time-slots'
            }
        ]
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
