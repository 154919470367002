import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { User } from 'app/core/user/user.types';
import { environment } from 'environments/environment';
import { BehaviorSubject, map, Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserService
{
    private _httpClient = inject(HttpClient);
    private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    private _selectUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    private _closeUser: BehaviorSubject<Boolean | null> = new BehaviorSubject<Boolean | null>(false);
    type_user: any;

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): BehaviorSubject<User>
    {
        return this._user;
    }

    set selectUser(value: User)
    {
        // Store the value
        this._selectUser.next(value);
    }

    get selectUser$(): BehaviorSubject<User>
    {
        return this._selectUser;
    }

    
    set closeUser(value: Boolean) {
        // Store the value
        this._closeUser.next(value);
    }

    get closeUser$(): BehaviorSubject<Boolean> {
        return this._closeUser;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<User>
    {
        return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) =>
            {
                this._user.next(user);
            }),
        );
    }

    addUser(user: User): Observable<any>
    {
        return this._httpClient.post<User>(`${environment.api}/auth/user/register`, user);
    }

    getAllUsers()
    {
        return this._httpClient.get(`${environment.api}/users/all`);
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any>
    {
        return this._httpClient.put<User>(`${environment.api}/user/update/`+user.id, user)
    }

    delete(user: User): Observable<any>
    {
        return this._httpClient.delete<User>(`${environment.api}/user/delete/`+user.id)
    }
}
