<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="bg-card dark:bg-gray-900 print:hidden" [appearance]="'thin'"
    [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20">
            <img class="w-8" src="assets/images/logo/logo55.png" alt="Logo image">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button class="mr-2" mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <languages></languages>
            <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
            <!-- <search [appearance]="'bar'"></search> -->
            <!-- <shortcuts></shortcuts> -->
            <!-- <messages></messages> -->
            <!-- <notifications></notifications> -->

            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button> -->

            <client></client>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
        class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Saintex &copy; {{currentYear}}</span>
    </div>

</div>
<fuse-drawer #reservationDetailsDrawer [fixed]="true" [mode]="'over'" [name]="'reservationDetailsDrawer'" width="700px"
    [opened]="reservationDetailsDrawer.opened" [position]="'right'">
    @if (reservationDetailsDrawer.opened){
    <div class="w-full bg-default">
        <div class="flex justify-between items-center border-b px-2 py-3 shadow bg-white">
            <button (click)="reservationDetailsDrawer.close()" mat-icon-button matTooltip="Close">
                <mat-icon svgIcon="heroicons_outline:x-mark"></mat-icon>
            </button>
            <h2 class="font-bold text-xl text-gray-700">Détail de la réservation
            </h2>
            <button mat-icon-button matTooltip="Go to configuration">
                <mat-icon svgIcon="heroicons_outline:external-link"></mat-icon>
            </button>
        </div>
        <div class="fullHeightDrawerContent h-full">
            <reservations-details class="w-full h-full"></reservations-details>
        </div>
    </div>
    }

</fuse-drawer>
<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->